
import {
  IonPage,
  IonContent,
  IonButton,
  IonText,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonIcon,
  IonModal,
  IonHeader,
  IonToolbar,
} from "@ionic/vue";
import { timeOutline, closeOutline } from "ionicons/icons";
import { defineComponent, ref, reactive } from "vue";
import { useI18n } from "vue-i18n";
import settingsApp from "@/libs/settings";
import httpApp from "@/libs/http";
import { useStore } from "@/store";
import { useRouter } from "vue-router";

import AccountNavComponent from "./AccountNavComponent.vue";
import MainHeader from "@/components/MainHeader.vue";
import MainFooter from "@/components/MainFooter.vue";
import Pagination from "@/components/Pagination.vue";

export default defineComponent({
  name: "Orders",
  setup() {
    const { t, tm } = useI18n();
    const storeX = useStore();
    const router = useRouter();
    const icons = reactive({
      timeOutline,
      closeOutline,
    });
    const orders = ref([]);
    const ordersLimit = ref(20);
    const ordersPage = ref(1);
    const ordersPages = ref(0);

    const currentOrder = ref(null);
    const currentOrderItems = ref([]);
    const isOpenRef = ref(false);

    const getAllOrders = (page = 1) => {
      ordersPage.value = page;
      httpApp
        .sendPostRequest(settingsApp.getEndpointUrl("getOrders"), {
          limit: ordersLimit.value,
          page: ordersPage.value,
        })
        .then(async (response: any) => {
          if (!Object.prototype.hasOwnProperty.call(response, "error")) {
            orders.value = response.items;
            ordersPages.value = response.pager.pages;
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    };

    const viewOrder = (order: any) => {
      httpApp
        .sendPostRequest(settingsApp.getEndpointUrl("getOrder"), {
          order_no: order.increment_id,
        })
        .then(async (response: any) => {
          if (!Object.prototype.hasOwnProperty.call(response, "error")) {
            currentOrder.value = response.order;
            currentOrderItems.value = response.items;
            if (currentOrderItems.value) {
              isOpenRef.value = true;
            }
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    };

    const getOrdertUrl = (order) => {
      const url =
        "/" + storeX.state.storeCode + "/account/order/" + order.increment_id;
      return url;
    };

    const nav = (path, event) => {
      event.preventDefault();
      router.push(path);
    };

    const formateDate = (date: any) => {
      const dateArr = date.split(" ");
      return dateArr[0];
    };
    const closeModal = () => {
      isOpenRef.value = false;
    };

    function ionViewWillEnter() {
      orders.value = [];
      ordersLimit.value = 20;
      ordersPage.value = 1;
      ordersPages.value = 0;
      getAllOrders();
    }
    return {
      t,
      tm,
      storeX,
      icons,
      orders,
      currentOrder,
      currentOrderItems,
      ionViewWillEnter,
      viewOrder,
      formateDate,
      getOrdertUrl,
      nav,
      ordersPages,
      ordersPage,
      getAllOrders,
      isOpenRef,
      closeModal,
    };
  },
  components: {
    IonPage,
    IonContent,
    IonButton,
    IonText,
    IonRow,
    IonCol,
    IonList,
    IonItem,
    IonIcon,
    IonModal,
    IonHeader,
    IonToolbar,
    AccountNavComponent,
    MainHeader,
    MainFooter,
    Pagination,
  },
});
